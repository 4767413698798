import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/LayoutView'
import PropertyListing from '../components/PropertyListing'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const PropertyByCategory = ({ data, pageContext }) => {
  const { propertyInfo, properties, cities, categoryTypes, property, propertyType, propertyCategory, locality, slug, city, newerprice, agent  } = data
  const loc = `${cities.nodes.map((city, index) => <p key={index}>{city.name}</p>)}`
  const title = `Property ${propertyInfo.name} in Lagos, Abuja and Dubai`
  const description= ` Check out our listings of ${title}`
   {/*const url = `property/${propertyType.slug/propertyCategory.slug/locality.city.slug/locality.slug/slug}`*/}
{/*const company = `${agent.name}`*/}

  return (
    <Layout>
       <GatsbySeo
      title= {title}
      description={description}
      openGraph={{
        title: {title},
        description: {description},
        url: 'https://www.example.com/articles/article-title',
        images: 
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/131ePdA3lUnISWi6UzYfq9/ef10c36ff7cef5ec33baa54fc809c167/apartments-and-houses-for-sale-ikate.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Og Image Alt Article Title A',
          },
        
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />
 
      <div className='pt-5 pd-bottom-70'>
        <div className='row mb-2 mt-4'>
          <div className='col-9 offset-md-3'>
          <h1 className=' text-center h4 my-4'>{title}</h1>
            <div className='d-flex justify-content-center align-items-center flex-wrap my-4'>
              {categoryTypes.nodes.map((type, index) => {
                return (
                  <h2 className='px-2 h6 text-muted border-end title readeal-top' key={index}>
                    <Link to={`/property/${propertyInfo.slug}/${type.slug}`}>
                      {type.name}
                    </Link>
                  </h2>
                )
              })}
            </div>
            <div className='d-flex justify-content-center align-items-center align-items-center flex-wrap my-4'>
              {cities.nodes.map((city, index) => {
                return (
                  <h2 className='px-2 h6 text-muted  text-center  border-end title readeal-top' key={index}>
                    <Link to={`/property/${propertyInfo.slug}/${city.slug}`}>
                      {city.name}
                    </Link>
                  </h2>
                )
              })}
            </div>
          </div>
        </div>
        <PropertyListing
          properties={properties}
          pageContext={pageContext}
          pathname={`/property/${propertyInfo.slug}`}
        />
      </div>
    </Layout>
  )
}

export default PropertyByCategory

export const query = graphql`
  query allPropertyCategoryQuery($slug: String!) {
    propertyInfo: contentfulPropertyCategory(slug: { eq: $slug }) {
      id
      name
      slug
      description {
        description
      }
    }

    categoryTypes: allContentfulPropertyCategoryByType {
      nodes {
        name
        slug
        id
      }
    }

    cities: allContentfulPropertyCategoryByCity {
      nodes {
        name
        slug
      }
    }

    properties: allContentfulProperty(
      filter: { propertyCategory: { slug: { eq: $slug } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...Property
      }
      ...PropertyPagination
    }
  }
`
